import { useEffect } from "react";
import { APP_STORE, GOOGLE_PLAY } from "./landing";

export const OpenAppPage = () => {
  const getPlatformLink = (): string => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;

    if (/iPhone|iPad|iPod/.test(userAgent)) {
      return APP_STORE
    }

    if (/android/i.test(userAgent)) {
      return GOOGLE_PLAY;
    }
    return ""
  };

  useEffect(() => {
    const link = getPlatformLink();
    if (link) {
      window.location.href = link
    }
  }, []);

  return (
    <div>
      <h2>Redirigiendo...</h2>
    </div>
  );
};
