export const langs: any = {
  es: {
    "photo-library": "Fototeca",
    "take-photo": "Tomar foto",
    "send-location": "Enviar localización",
    "write-here": "Escribe aquí",
    location: "Localización",
    loading: "Cargando",
    request: "Solicitar",
    jobs: "Trabajos",
    services: "Servicios",
    account: "Cuenta",
    "enter-your-phone-number": "Introduce tu número de teléfono",
    "enter-the code": "Introduce el código",
    "we-will-send-a-code-to-verify":
      "Te enviaremos un código para verificar tu teléfono",
    "we-sent-a-code-to-verify":
      "Te enviamos un código para verificar tu teléfono",
    "when-join-you-accept": "Al unirte a nuestra aplicación, aceptas nuestros",
    terms: " Términos de uso ",
    and: "y",
    "private-policy": " política de privacidad",
    "send-code": "Enviar código",
    "verify-code": "Verificar código",
    "resend-code": "Reenviar código",
    "resend-code-2": "en",
    "resend-code-3": "segundos",
    username: "Nombre de usuario",
    "personal-data": "Datos personales",
    ids: "Identificaciones",
    "vehicle-data": "Datos de vehículo",
    es: "Español",
    en: "Inglés",
    settings: "Configuraciones",
    "go-to-driver-mode": "Ir a modo conductor",
    "go-to-user-mode": "Ir a modo usuario",
    logout: "Cerrar sesión",
    "add-id-front": "Añadir cédula, parte frontal",
    "add-id-back": "Añadir cédula, parte trasera",
    "add-licence-front": "Añadir licencia, parte frontal",
    "add-licence-back": "Añadir licencia, parte trasera",
    "vehicle-year": "Año de vehículo",
    "vehicle-make": "Marca del vehículo",
    "vehicle-model": "Modelo del vehículo",
    "vehicle-color": "Color del vehículo",
    "plate-number": "Número de placa",
    "vehicle-registration": "Añadir el registro vehicular",
    "vehicle-insurance": "Añadir el seguro vehicular",
    "you-sent-a-request":
      "Enviaste una solicitud para ser conductor. Pronto te notificaremos",
    "your-driver-account-was-approved": "Tu cuenta de conductor fue aprobada",
    "change-photo": "Cambiar foto",
    name: "Nombre",
    lastname: "Apellido",
    birthdate: "Fecha de Nacimiento",
    language: "Idioma",
    "yappy-number": "Número de Yappy",
    copied: "Copiado",
    "yappy-copied": "Numero de yappy, copiado con exito",
    save: "Guardar",
    "send-request": "Enviar solicitud",
    next: "Siguiente",
    "available-jobs": "Trabajos disponibles",
    "no-services-available-in-zone": "No hay servicios disponibles en tu zona",
    from: "De",
    to: "A",
    "custom-price": "Precio personalizado",
    meters: "metros",
    kilometers: "kilómetros",
    "client-location": "Ubicación del cliente",
    "driver-will-arrive-this-location": "El conductor llegará a esta ubicación",
    "destination-location": "Ubicación de destino",
    "driver-will-take-client-this-location":
      "El conductor llevará al cliente a esta ubicación",
    "driver-location": "Ubicación del conductor",
    "this-is-driver-location": "Esta es la ubicación del conductor",
    "offered-fee-waiting": "Ofreciendo su tarifa, espere la respuesta",
    "accept-for": "Aceptar por",
    "accept-with-custom-fee": "Aceptar con tarifa personalizada",
    "offer-your-fee": "Ofrezca su tarifa",
    close: "Cerrar",
    "i-just-arrived": "He llegado",
    "service-finished": "Servicio finalizado",
    "to-show": "Mostrar",
    map: "Mapa",
    messages: "Mensajes",
    confirm: "Confirmar",
    "set-your-fee": "Pon tu tarifa",
    recommended: "recomendado",
    "service-was-taken-by-other-driver":
      "El servicio fue tomado por otro conductor",
    "user-canceled-service": "El usuario canceló el servicio",
    "you-sure-want-to-cancel":
      "¿Estás seguro de que deseas cancelar este servicio?",
    "confirm-cancelation": "Confirmar cancelación",
    "cancel-service": "Cancelar servicio",
    "client-is-not-in-location": "Cliente no se encuentra en ubicación",
    "cant-do-service": "No puedo dar el servicio",
    other: "Otro",
    "custom-location": "Locación personalizada",
    "open-location": "Abrir ubicación de",
    destiny: "Destino",
    user: "Usuario",
    "put-your-fee": "Coloca tu tarifa",
    cancel: "Cancelar",
    "location-permissions-required": "Permisos de ubicación requeridos",
    "location-permissions-required-description":
      "Para activar los permisos de ubicación manualmente, ve a la configuración de la aplicación.",
    config: "Configuración",
    "set-location-in-map": "Establecer la ubicación en el mapa.",
    "my-location": "Mi ubicación",
    "stating-location": "Punto de partida",
    "where-are-you-going": "¿A dónde vas?",
    "offer-a-price": "Ofrece un precio",
    "request-car": "Solicitar auto",
    "tell-us": "Cuéntanos",
    "rate-your-last-service": "Califica el último servicio brindado por",
    send: "Enviar",
    "confirm-price": "Confirmar precio",
    "select-location": "Seleccionar ubicación",
    "drivers-will-appear-here": "Los conductores aparecerán aquí en un momento",
    accept: "Aceptar",
    decline: "Rechazar",
    "you-dont-have-services": "No tienes servicios",
    distance: "Distancia",
    canceled: "Cancelado",
    finished: "Finalizado",
    "pending-comission": "Comision pendiente",
    comission: "Comision",
    our_schedule_is: "Nuestro horario es:",
    "day-0": "Domingo",
    "day-1": "Lunes",
    "day-2": "Martes",
    "day-3": "Miércoles",
    "day-4": "Jueves",
    "day-5": "Viernes",
    "day-6": "Sábado",
    "thanks-for-rating": "Gracias por tu calificacion",
    "welcome": "Bienvenido",
    "invited-you-to-join": "te invita a unirte a",
    "ideal-app": "la app ideal para tus viajes y entregas.",
    "error-referring": "Hubo un error, revisa si ya existe un usuario con este numero",
    "download-app": "Descargar App",
  },
  en: {
    "photo-library": "Photo Library",
    "take-photo": "Take Photo",
    "send-location": "Send Location",
    "write-here": "Write Here",
    location: "Location",
    loading: "Loading",
    request: "Request",
    jobs: "Jobs",
    services: "Services",
    account: "Account",
    "enter-your-phone-number": "Enter Your Phone Number",
    "enter-the code": "Enter the Code",
    "we-will-send-a-code-to-verify": "We will send a code to verify",
    "we-sent-a-code-to-verify": "We sent a code to verify",
    "when-join-you-accept": "When you join, you accept our",
    terms: " Terms of Use ",
    and: "and",
    "private-policy": " Privacy Policy",
    "send-code": "Send Code",
    "verify-code": "Verify Code",
    "resend-code": "Resend Code",
    "resend-code-2": "in",
    "resend-code-3": "seconds",
    username: "Username",
    "personal-data": "Personal Data",
    ids: "IDs",
    "vehicle-data": "Vehicle Data",
    es: "Spanish",
    en: "English",
    settings: "Settings",
    "go-to-driver-mode": "Go to Driver Mode",
    "go-to-user-mode": "Go to User Mode",
    logout: "Logout",
    "add-id-front": "Add ID, Front Part",
    "add-id-back": "Add ID, Back Part",
    "add-licence-front": "Add Licence, Front Part",
    "add-licence-back": "Add Licence, Back Part",
    "vehicle-year": "Vehicle Year",
    "vehicle-make": "Vehicle Make",
    "vehicle-model": "Vehicle Model",
    "vehicle-color": "Vehicle Color",
    "plate-number": "Plate Number",
    "vehicle-registration": "Add Vehicle Registration",
    "vehicle-insurance": "Add Vehicle Insurance",
    "you-sent-a-request":
      "You sent a request to be a driver. We will notify you soon",
    "your-driver-account-was-approved": "Your driver account was approved",
    "change-photo": "Change Photo",
    name: "Name",
    lastname: "Lastname",
    birthdate: "Birthdate",
    language: "Language",
    "yappy-number": "Yappy Number",
    copied: "Copied",
    "yappy-copied": "Yappy number, copied successfully",
    save: "Save",
    "send-request": "Send Request",
    next: "Next",
    "available-jobs": "Available Jobs",
    "no-services-available-in-zone": "No services available in your zone",
    from: "From",
    to: "To",
    "custom-price": "Custom Price",
    meters: "meters",
    kilometers: "kilometers",
    "client-location": "Client Location",
    "driver-will-arrive-this-location": "Driver will arrive at this location",
    "destination-location": "Destination Location",
    "driver-will-take-client-this-location":
      "Driver will take client to this location",
    "driver-location": "Driver Location",
    "this-is-driver-location": "This is driver's location",
    "offered-fee-waiting": "Offering your fee, wait for response",
    "accept-for": "Accept for",
    "accept-with-custom-fee": "Accept with custom fee",
    "offer-your-fee": "Offer your fee",
    close: "Close",
    "i-just-arrived": "I just arrived",
    "service-finished": "Service finished",
    "to-show": "To show",
    map: "Map",
    messages: "Messages",
    confirm: "Confirm",
    "set-your-fee": "Set your fee",
    recommended: "recommended",
    "service-was-taken-by-other-driver": "Service was taken by another driver",
    "user-canceled-service": "User canceled the service",
    "you-sure-want-to-cancel": "Are you sure you want to cancel this service?",
    "confirm-cancelation": "Confirm cancellation",
    "cancel-service": "Cancel service",
    "client-is-not-in-location": "Client is not in location",
    "cant-do-service": "Can't do service",
    other: "Other",
    "custom-location": "Custom Location",
    "open-location": "Open Location of",
    destiny: "Destiny",
    user: "User",
    "put-your-fee": "Put your fee",
    cancel: "Cancel",
    "location-permissions-required": "Location permissions required",
    "location-permissions-required-description":
      "To manually activate location permissions, go to the app settings.",
    config: "Config",
    "set-location-in-map": "Set location on map.",
    "my-location": "My location",
    "stating-location": "Starting location",
    "where-are-you-going": "Where are you going?",
    "offer-a-price": "Offer a price",
    "request-car": "Request car",
    "tell-us": "Tell us",
    "rate-your-last-service": "Rate your last service provided by",
    send: "Send",
    "confirm-price": "Confirm price",
    "select-location": "Select location",
    "drivers-will-appear-here": "Drivers will appear here in a moment",
    accept: "Accept",
    decline: "Decline",
    "you-dont-have-services": "You don't have services",
    distance: "Distance",
    canceled: "Canceled",
    finished: "Finished",
    "pending-comission": "Pending Commission",
    comission: "Commission",
    our_schedule_is: "Our schedule is:",
    "day-0": "Sunday",
    "day-1": "Monday",
    "day-2": "Tuesday",
    "day-3": "Wednesday",
    "day-4": "Thursday",
    "day-5": "Friday",
    "day-6": "Saturday",
    "thanks-for-rating": "Thanks for your rating",
    "welcome": "Welcome",
    "invited-you-to-join": "invited you to join",
    "ideal-app": "the ideal app for your trips and deliveries.",
    "error-referring": "There was an error, check if a user with this number already exists",
    "download-app": "Download app",
  },
};
