import { Box, Button, Container, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IUsers } from "../utils/schemas/user";
import { useLangs } from "../hooks/useLangs/useLangs";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import logo from "../assets/logo.png"

export const ReferPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)
  const [referData, setReferData] = useState<IUsers | null>(null)
  const { userId } = useParams();
  useEffect(() => {
    if (userId) {
      axios
        .get(`${process.env.REACT_APP_API_LINK}/api/refer/${userId}`)
        .then(({ data }) => {
          setReferData(data.user)
          console.log("data", data)
        });
    }
  }, [userId]);
  const [phone, setPhone] = useState<string | undefined>("undefined");
  const translate = useLangs();

  const downloadApp = () => {
    if (userId) {
      setIsLoading(true)
      axios
        .post(`${process.env.REACT_APP_API_LINK}/api/refer`, { userId, phone: phone?.split(" ").join("") })
        .then(() => {
          navigate('/open-app')
          setIsLoading(false)
        }).catch(() => {
          navigate('/open-app')
          setIsLoading(false)
        });
    }
  }

  const isValid = useMemo(() => {
    return matchIsValidTel(phone ?? "", {
      onlyCountries: [],
      excludedCountries: [],
      continents: []
    })
  }, [phone])

  return !referData ? null : (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          padding: 2,
        }}
      >
        <Paper elevation={3} sx={{ padding: 3, width: "100%" }}>
          <img src={logo} alt="Un Bote Logo" className="logo" />
          <Typography variant="h4" gutterBottom>
            ¡{translate("welcome")}!
          </Typography>
          <Typography variant="h6" paragraph>
            {referData.name ?? ""} {referData.lastname ?? ""} {translate("invited-you-to-join")} <strong>UN BOTE</strong>, {translate("ideal-app")}
          </Typography>

          <MuiTelInput
            fullWidth
            defaultCountry='PA'
            value={phone}
            onChange={setPhone}
            placeholder={translate("enter-your-phone-number")}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={downloadApp}
            disabled={!isValid || isLoading}
          >
            {translate("download-app")}
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};
